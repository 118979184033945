import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import "../../assets/css/ProvideAccessDetail.css"
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { SocketContext } from "../../context/socket";
import { getUserFromLocalStorage } from '../../utils/localStorage';
import { toast } from "react-toastify";
import api from '../../utils/axios';
import { API_URL } from '../../constants/constants';
import Lock from "../../assets/images/lock.png"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const ProvideAccessDetail = ({ show, setShow, isFirstTask }) => {
    const { t } = useTranslation();
    const ref = React.useRef(null);
    const socket = React.useContext(SocketContext);
    const currenTask = useSelector((state) => state.current);
    const userData = getUserFromLocalStorage();

    const TAB = {
        WORDPRESS: 0,
        FTP: 1
    }

    const [selectedTab, setSelectedTab] = useState(TAB.WORDPRESS);

    const [licenseKeyWP, setLicenseKeyWP] = useState('')
    const [domainWP, setDomainWP] = useState('')
    const [usernameWP, setUsernameWP] = useState('')
    const [passwordWP, setPasswordWP] = useState('')

    const [licenseKeyFTP, setLicenseKeyFTP] = useState("")
    const [domainFTP, setDomainFTP] = useState('')
    const [usernameFTP, setUsernameFTP] = useState('')
    const [passwordFTP, setPasswordFTP] = useState('')

    const [validated, setValidated] = useState(false);

    const isUrl = (str) => {
        const pattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
        return pattern.test(str);
    };

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (ref.current && !ref.current.contains(e.target)) {
                setShow(false);
            }
        };
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!licenseKeyFTP && !domainFTP && !usernameFTP && !passwordFTP && !licenseKeyWP && !domainWP && !usernameWP && !passwordWP) {
            toast.warning("Empty data");
            return;
        }
        const clientData = {
            taskId: currenTask._id,
            licenseKeyWP: licenseKeyWP,
            domainWP: domainWP,
            usernameWP: usernameWP,
            passwordWP: passwordWP,
            licenseKeyFTP: licenseKeyFTP,
            domainFTP: domainFTP,
            usernameFTP: usernameFTP,
            passwordFTP: passwordFTP,
        };

        try {
            await api.post(`${API_URL}/api/v1/chatbot/provide-access-detail`, clientData)
                .then((res) => {
                    toast.success("Saved access data");
                    if (isFirstTask) socket.emit("handle-provide-site", { task: currenTask, userId: userData._id, type: "handle_provide_site--yes" })
                    else socket.emit("handle-provide-site", { task: currenTask, userId: userData._id, type: "request_provide_data-yes" })
                })
                .catch(err => {
                    toast.error("Error saving access data");
                })
        } catch (err) {
            const errors = err.response.data.errors;
            errors.forEach((error) => toast.error(error.msg));
        }

        setShow(false)
    }


    return (
        <>
            {
                show && (
                    <div className="provide-site-form d-flex justify-content-center align-items-center">
                        <div ref={ref} style={{ width: '30%', padding: '30px 20px' }} className="contact-manager-model access-detail-form">
                            <div className="d-flex justify-content-between mb-4">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center", gap: "10px" }}>
                                    <h2 style={{ fontWeight: '500', fontSize: '22px' }}>{t("your_data_will_be_encrypted")}</h2>
                                    <img src={Lock} />
                                </div>
                                <button className="cancel" onClick={() => setShow(false)}>
                                    <i className="bi bi-x-lg"></i>
                                </button>
                            </div>

                            <div className="tab">
                                <ul>
                                    <li
                                        className={selectedTab == TAB.WORDPRESS ? "active" : ""}
                                        onClick={() => setSelectedTab(TAB.WORDPRESS)}
                                    >
                                        Wordpress
                                    </li>
                                    <li
                                        className={selectedTab == TAB.FTP ? "active" : ""}
                                        onClick={() => setSelectedTab(TAB.FTP)}
                                    >
                                        FTP
                                    </li>
                                </ul>
                            </div>

                            <div>
                                {
                                    selectedTab === TAB.WORDPRESS && <Form noValidate validated={validated} >
                                        <Form.Group hasValidation className="mb-3" >
                                            <Form.Label htmlFor='licensekey'>{t("license_key")}</Form.Label>
                                            <InputGroup className="mb-3">
                                                <Form.Control required id="licensekey" value={licenseKeyWP} onChange={(e) => { setLicenseKeyWP(e.target.value) }} placeholder="ex: 1111-2222-3333-4444" />
                                                <Form.Control.Feedback type="invalid">
                                                    Please enter license key.
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group hasValidation className="mb-3" >
                                            <Form.Label htmlFor='domain'> {t("domain")}</Form.Label>
                                            <InputGroup className="mb-3">
                                                <Form.Control required id="domain" value={domainWP} onChange={(e) => { setDomainWP(e.target.value) }} placeholder='ex: codemenschen.at' />
                                                <Form.Control.Feedback type="invalid">
                                                    Please enter domain.
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group hasValidation className="mb-3" >
                                            <Form.Label htmlFor='username'> {t("username")}</Form.Label>
                                            <Form.Control required id='username' type="username" value={usernameWP} onChange={(e) => setUsernameWP(e.target.value)} placeholder="ex: codemenschen.at@gmail.com" />
                                            <Form.Control.Feedback type="invalid">
                                                Please enter password
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group hasValidation className="mb-3" >
                                            <Form.Label htmlFor='password'>{t("password")}</Form.Label>
                                            <Form.Control required id='password' type="password" value={passwordWP} onChange={(e) => setPasswordWP(e.target.value)} placeholder="ex: codemenschen.at" />
                                            <Form.Control.Feedback type="invalid">
                                                Please enter password.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Button style={{ width: '100%' }} onClick={handleSubmit} variant="primary" type='submit'>
                                            {t("save_access_details")}
                                        </Button>
                                    </Form>
                                }

                                {
                                    selectedTab === TAB.FTP && <Form noValidate validated={validated} >
                                        <Form.Group hasValidation className="mb-3" >
                                            <Form.Label htmlFor='licensekey'>{t("license_key")}</Form.Label>
                                            <InputGroup className="mb-3">
                                                <Form.Control required id="licensekey" value={licenseKeyFTP} onChange={(e) => { setLicenseKeyFTP(e.target.value) }} placeholder="ex: 1111-2222-3333-5555" />
                                                <Form.Control.Feedback type="invalid">
                                                    Please enter license key.
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group hasValidation className="mb-3" >
                                            <Form.Label htmlFor='domain'> {t("domain")}</Form.Label>
                                            <InputGroup className="mb-3">
                                                <Form.Control required id="domain" value={domainFTP} onChange={(e) => { setDomainFTP(e.target.value) }} placeholder='ex: wp-sofa.chat' />
                                                <Form.Control.Feedback type="invalid">
                                                    Please enter domain
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group hasValidation className="mb-3" >
                                            <Form.Label htmlFor='username'> {t("username")}</Form.Label>
                                            <Form.Control required id='username' type="username" value={usernameFTP} onChange={(e) => setUsernameFTP(e.target.value)} placeholder="ex: example@gmail.com" />
                                            <Form.Control.Feedback type="invalid">
                                                Please enter password
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group hasValidation className="mb-3" >
                                            <Form.Label htmlFor='password'>{t("password")}</Form.Label>
                                            <Form.Control required id='password' type="password" value={passwordFTP} onChange={(e) => setPasswordFTP(e.target.value)} placeholder="ex: password_123" />
                                            <Form.Control.Feedback type="invalid">
                                                Please enter password.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Button style={{ width: '100%' }} onClick={handleSubmit} variant="primary" type='submit'>
                                            {t("save_access_details")}
                                        </Button>
                                    </Form>
                                }
                            </div>

                            {/* <div className='tabs'>
                                <Form noValidate validated={validated} >
                                    <Form.Group hasValidation className="mb-3" >
                                        <Form.Label htmlFor='licensekey'>{t("license_key")}</Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control required id="licensekey" value={licenseKey} onChange={(e) => { setLicenseKey(e.target.value) }} placeholder={t("license_key")} />
                                            <Form.Control.Feedback type="invalid">
                                                Please enter license key.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group hasValidation className="mb-3" >
                                        <Form.Label htmlFor='domain'> {t("domain")}</Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control required id="domain" value={domain} onChange={(e) => { setDomain(e.target.value) }} placeholder={webType === "FTP" ? "ftp://" : "https://"} />
                                            <Form.Control.Feedback type="invalid">
                                                Please enter domain.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group hasValidation className="mb-3" >
                                        <Form.Label htmlFor='username'> {t("username")}</Form.Label>
                                        <Form.Control required id='username' type="username" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Ex: example@gmail.com" />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter password
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group hasValidation className="mb-3" >
                                        <Form.Label htmlFor='password'>{t("password")}</Form.Label>
                                        <Form.Control required id='password' type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Ex: 12345678" />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter password.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Button style={{ width: '100%' }} onClick={handleSubmit} variant="primary" type='submit'>
                                        {t("save_access_details")}
                                    </Button>
                                </Form>
                            </div> */}

                        </div>
                    </div>
                )
            }
        </>
    )
}

export default ProvideAccessDetail